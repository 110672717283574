/* eslint-disable no-undef */
/*
 * @Autor: syq
 * @Date: 2021-07-22 21:31:53
 */
import moment from "moment";

export function handleCommonTimeRender(text) {
  const formatType = "YYYY-MM-DD HH:mm:ss";
  return text && moment(text).format(formatType);
}

export const trackEvent = (pageName, eventName, information) => {
  try {
    let currentUrl = location.search
    let sessionId = ""
    if (currentUrl.includes("sessionId") && currentUrl.split("=")[1]) {
      sessionId = currentUrl.split("=")[1]
    }
  
    if (!pageName) {
      switch (location.hash.split("?")[0]) {
        case "#/home":
          pageName = "首页";
          break;
        case "#/classification":
          pageName = "分类页面";
          break;
        case "#/stores":
          pageName = "店铺页面";
          break;
        case "#/mine":
          pageName = "我的页面";
          break;
        case "#/market":
          pageName = "购物车页面";
          break;
      }
    }
  
    if (!pageName) return null;
  
    if (_hmt) {
      _hmt.push(["_trackEvent", `${pageName}`, eventName, sessionId, information]);
    }
  } catch (e) {}
}